import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Language understanding',
    text: 'GPT-3 has a deep understanding of the nuances of human language, which allows it to generate human-like text, answer questions, and perform other language-based tasks with high accuracy.',
  },
  {
    title: 'Large training data',
    text: 'GPT-3 has been trained on a massive dataset of texts from the internet, which allows it to have a wide range of knowledge and generate text on a variety of topics.',
  },
  {
    title: 'Fine Tuning',
    text: 'GPT-3 can perform tasks with very little training data, which means it can be fine-tuned to perform specific tasks with minimal effort.',
  },
  {
    title: 'Multitasking',
    text: 'GPT-3 can perform a wide range of language-based tasks such as text generation, question answering, summarization, and machine translation all with the same model.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
